/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta, MapInfo, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "headphones-with-hearing-aids-and-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#headphones-with-hearing-aids-and-hearing-loss",
    "aria-label": "headphones with hearing aids and hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Headphones with hearing aids and hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Headphones are an everyday device for most of us. We use them to listen to music, speech, or radio, without disturbing others. They also allow us to watch movies and TV shows in buses or planes. But as anyone who has experienced hearing loss will tell you, using headphones with ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " can be problematic."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Let’s look at some of the issues and try to suggest solutions for hearing aid users who want to enjoy the music they love with their favorite headphones."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-headphones-and-hearing-loss-relate-to-each-other",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-headphones-and-hearing-loss-relate-to-each-other",
    "aria-label": "how headphones and hearing loss relate to each other permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How headphones and hearing loss relate to each other"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are a number of problems associated with headphone use. For one thing, feedback is a major issue when you wear both headphones and hearing aids. When the headphones press against parts of your hearing aid, the signals from both can become mixed, causing the classic “whistling” noise which is so jarring for the human ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s also the problem of volume. When you don’t experience hearing loss, you can pretty much tell how loud a song is and adjust it accordingly. But when you have a hearing aid turned on, it becomes slightly harder to tell exactly how many decibels you are pumping into your ear. Music fans will often turn their volume up too high, when they could adjust the sensitivity of their hearing device instead."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, there’s the physical challenge of wearing both hearing aids and headphones comfortably. Thanks to changes in headphone design, there are ways to do so without any discomfort, but they don’t necessarily deliver stellar sound quality. For instance, bone conduction headphones don’t require contact with the ear at all, so they are great for using ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite",
    className: "c-md-a"
  }, "with ITE hearing aids"), ". But because they send soundwaves through the jaw, they lose a little bit of fidelity in the process."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "things-to-remember-about-using-headphones-with-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#things-to-remember-about-using-headphones-with-your-hearing-aids",
    "aria-label": "things to remember about using headphones with your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Things to remember about using headphones with your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Regardless of the issues associated with wearing headphones and hearing aids, many of us will still wear both of them together. But if you are dead set on continuing your audio listening habits, what should you remember when wearing them with hearing aids at the same time?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One thing to bear in mind is that your old headphones probably won’t remain suitable for long. Experiment with the equipment you have (but be careful with volume levels), and try to find a setup which feels comfortable and avoids squalls of feedback. This might not be easy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The truth is that you will most likely need a special pair of headphones that are physically designed to slide over ITE or ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte",
    className: "c-md-a"
  }, "BTE hearing aids"), ". They should also feature noise cancellation and anti-feedback systems which ensure a decent level of sound quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another thing to remember is that most hearing aids have settings for “music” which take into account headphones. They may not be perfect, but these settings alter the gain levels on hearing aids, helping to establish a listenable balance. If you have mild to moderate hearing loss, this can be enough to make headphones a viable option."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, as we noted above, at higher levels of hearing loss, special listening equipment will be essential. The question is, how do you go about selecting a pair of headphones that will work seamlessly with your hearing aid?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-choose-the-right-headphones-for-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-choose-the-right-headphones-for-your-hearing-aids",
    "aria-label": "how to choose the right headphones for your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to choose the right headphones for your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Firstly, be aware of the three types of headphones: bone conduction, over-the-ear, and on-ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over-the-ear headphones are generally to be avoided because of their large cup size, which tends to come into direct contact with hearing aid microphones, causing feedback. On the other hand, well-made over-the-ear headphones can work around feedback issues, and actually work really well thanks to their superior noise cancellation properties."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On-ear headphones can produce similar problems with feedback, but are smaller, lighter, and can work well with ITE and ITC hearing aids. In these devices, the speaker is placed directly on top of the hearing aid receiver, which should produce excellent sound quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, bone conduction headphones are the least likely to cause feedback, but aren’t the best for sound quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of these types have their strengths, but all have the weaknesses that come with combining an amplified sound source with standard hearing aids. As for earbuds, don’t even consider it. There’s no way to wear in-the-ear music devices and hearing aids at the same time, and they can actually damage your hearing even more."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Try a few models for size, play a few tracks from your smartphone, and watch out for feedback or discomfort. You may well find that with lower levels of hearing loss, headphones work well enough. But you might not. Don’t become frustrated though, as there is an alternative: hearing aids which offer built-in features for music lovers."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-can-actually-let-you-dispense-with-headphones-if-you-prefer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-can-actually-let-you-dispense-with-headphones-if-you-prefer",
    "aria-label": "hearing aids can actually let you dispense with headphones if you prefer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids can actually let you dispense with headphones if you prefer"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These auditory devices are potentially a game-changer for people who are suffering from hearing loss but adore music. The industry has even come up with a catchy name for these trailblazing gadgets, dubbing them “hearables.”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The idea behind hearables is that by combining advanced sound processing computers, wireless technology and high-quality hearing aids, users can achieve perfectly balanced sound quality. In theory, with these devices, music output from stereos, TVs, or smartphones can be matched to individual needs and tastes, all without the risk of feedback."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth connectivity"), ", hearing aids like ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix",
    className: "c-md-a"
  }, "the Horizon Go IX"), " can cut out the middle man, making it unnecessary to bother with headphones at all."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s an elegant ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "application of high technology to everyday hearing loss situations"), ", and it’s definitely something that audiophiles should consider asking about when they have a hearing loss consultation.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try Horizon Go IX today",
    classes: "c-cta--content"
  }), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
